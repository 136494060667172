import { getJumbotronData } from 'common/components/organisms/Jumbotron'
import { IncomeTaxRelief } from 'common/components/templates/IncomeTaxRelief'
import { getLayoutData } from 'common/utils/getLayoutData'
import React from 'react'

export interface IncomeTaxReliefPageProps {
    pageContext: any
}

export const IncomeTaxReliefPage: React.FC<IncomeTaxReliefPageProps> = ({
    pageContext
}) => {
    return <IncomeTaxRelief {...getIncomeTaxReliefData(pageContext)} />
}
export default IncomeTaxReliefPage

export const getIncomeTaxReliefData = (data: any) => ({
    layout: getLayoutData(data),
    alerts: {
        alerts: (data.alerts__alerts || []).map((alert: any) => alert.text)
    },
    benefits: {
        benefits: data.threecards__cards,
        subtitle: data.threecards__subtitle,
        title: data.threecards__title
    },
    button: {
        analyticsId: data.button_c9ad60e__analytics_id,
        endIcon: data.button_c9ad60e__icon,
        label: data.button_c9ad60e__text,
        url: data.button_c9ad60e__url
    },
    resources: {
        title: data.resources__title,
        text: data.resources__text,
        subtitle: data.resources__subtitle,
        downloads: data.resources__downloads
    },
    jumbotron: getJumbotronData(data),
    textWithTable: {
        text: data.text,
        title: data.title,
        codeName: data.codename
    }
})
