import React from 'react'
import { Box } from '@material-ui/core'
import {
    JumbotronCallToActionProps,
    JumbotronCallToAction
} from '../Jumbotron/JumbotronCallToAction'

export interface TemplateButtonProps
    extends Omit<JumbotronCallToActionProps, 'variant'> {}

export const TemplateButton: React.FC<TemplateButtonProps> = props => {
    return (
        <Box display='flex' justifyContent='center'>
            <JumbotronCallToAction {...props} variant='outlined' />
        </Box>
    )
}
